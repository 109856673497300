<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal">
      <h2 class="align-self-center">
        برداشت
        {{ $coinLabel[coin] }}
      </h2>
      <div class="title-text d-flex flex-col">
        <p class="regular">
          درخواست های برداشت به صورت دستی تایید و سپس پردخت خواهند شد.کارمزد
          انتقال از مبلغ برداشت کسر خواهد شد.
        </p>
        <p class="regular">
          در صورتی که آدرس مقصد متعلق به کاربران هاب باشد انتقال به صورت مستقیم
          و آنی صورت می گیرد و کارمزد انتقال صفر خواهد بود.
        </p>

        <template v-show="!isNetwork">
          <div class="drop-container d-flex flex-col gap-xss">
            <button class="align-self-start" @click="drop = !drop">
              شبکه
              {{ selected_net }}
            </button>
            <div class="drop d-flex flex-col gap-xss pointer" v-if="drop">
              <span
                class="hover"
                v-for="(item, index) in networks"
                :key="index"
                @click="selectNet(item)"
                >{{ item.tokenType }}</span
              >
            </div>
          </div>
        </template>

        <div class="fee-network d-flex flex-col" v-show="isNetwork">
          <div class="row d-flex justify-space-between w100">
            <span class="d-flex small gray">
              <img
                src="@/assets/Icons/warning.png"
                alt=""
                width="20"
                height="20"
              />
              کارمزد شبکه
            </span>
            <span class="small gray">{{ fee }}</span>
          </div>
          <div class="row d-flex justify-space-between w100">
            <span class="d-flex small gray">
              <img
                src="@/assets/Icons/warning.png"
                alt=""
                width="20"
                height="20"
              />
              حداقل حداکثر مقدار قابل برداشت
            </span>
            <span class="small gray rtl">
              {{ min }} {{ coin }} {{ max }} - {{ coin }}
            </span>
          </div>
        </div>
      </div>
      <template v-if="isNetwork">
        <div class="content-container d-flex flex-col">
          <div class="d-flex justify-space-between w100 cont">
            <span class="small gray">موجودی شما:</span>
            <span class="small gray ltr">{{
              `${$toLocal(inventory, $decimal[coin])} ${$coinUnit[coin]}`
            }}</span>
          </div>
          <input
            class="input w100"
            type="text"
            placeholder="مبلغ برداشت"
            v-model="amount"
          />
          <input
            class="input w100"
            type="text"
            placeholder="کیف پول مقصد"
            v-model="walletAddress"
          />
          <input
            v-if="otpSent"
            class="input w100"
            type="text"
            placeholder="شناسه دو عاملی"
            v-model="code"
          />
          <span v-if="otpSent" class="timer align-self-end small">{{
            countdown
          }}</span>
        </div>
        <button
          class="btn-primary w100"
          :disabled="!!disabledButton"
          @click.prevent="withdraw()"
          v-if="!otpSent"
        >
          {{ !!disabledButton ? disabledButton : "ارسال کد" }}
        </button>
        <button
          class="btn-primary w100"
          v-if="timeout"
          @click="(countdown = 10), (timeout = !timeout), countDownTimer()"
        >
          ارسال مجدد
        </button>
        <button
          class="btn-primary w100"
          v-if="!timeout && otpSent"
          @click.prevent="submitWithdrawRequest"
        >
          تایید
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Withdraw",
  props: ["coin"],
  data() {
    return {
      otpSent: false,
      isNetwork: false,
      fee: 0,
      max: 0,
      min: 0,
      drop: false,
      selected_net: " را انتخاب نمایید",
      networks: "",
      countdown: 120,
      inventory: 0,
      timeout: false,
      walletAddress: "",
      amount: null,
      timeOut: null,
      code: "",
      transaction: {
        amount: "",
        destinationWalletAddress: "",
        relatedCoin: "",
          tokenType: "",
        code: "",
        walletType: "P2P"
      },
    };
  },
  methods: {
    
    async getNetwork() {
      const res = await this.$axios.get("/coins");
      const coin = res.content.find((coin) => coin.coin === this.coin);
      this.networks = coin.tokenTypeDetails;
    },

    selectNet(item) {
      console.log(item);
      this.selected_net = item.tokenType;
      this.fee = item.withdrawalFee;
      this.min = item.minWithdraw;
      this.max = item.maxWithdraw;
      this.drop = !this.drop;
      this.isNetwork = true;
    },
        async submitWithdrawRequest() {
      this.state.loading = true;
      this.transaction.code = this.code;
      const [res] = await this.$http.post(
          "/wallets/withdrawal-request",
          this.transaction
      );
      if (res) {
        this.$emit("close");
        this.$error("درخواست شما با موفقیت ثبت شد", "", "success");
        ++this.state.requestChanged;
      }
    },
    async getWallets() {
      this.state.loading = true;
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true;
      const [res] = await this.$http.get(
        "/wallets/customer-addresses?walletType=P2P"
      );

      if (res) {
        // let entireRes = Object.entries(res)
        // const sortCoins = this.$walletSortCoins
        // entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.inventory = this.$toLocal(res[this.coin].credit);
      }
      this.loading = false;
    },
    countDownTimer() {
      if (this.countdown > 0) {
        this.timeOut = setTimeout(() => {
          this.countdown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");
        this.timeout = true;
      }
    },


  },

  computed: {
    disabledButton() {
      let Errors = "";
      if (!this.$S2N(this.amount)) {
        Errors = "مقدار برداشت را وارد کنید";
      } else if (this.$S2N(this.amount) > this.$S2N(this.inventory)) {
        Errors = "مقدار برداشت بیشتر از موجودی کیف پول است";
      } else if (this.$S2N(this.amount) > this.$S2N(this.max)) {
        Errors = "مقدار برداشت بیشتر از حداکثر میزان برداشت می باشد";
      } else if (this.$S2N(this.amount) < this.$S2N(this.min)) {
        Errors = "مقدار برداشت کمتر از حداقل میزان برداشت می باشد";
      } else if (!this.walletAddress) {
        Errors = "آدرس کیف پول مقصد را وارد کنید";
      }

      return Errors;
    },
  },

  mounted() {
    this.getWallets();
    this.getNetwork();
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
};
</script>

<style lang="scss" scoped>
.drop-container {
  position: relative;
  .drop {
    position: absolute;
    top: 40px;
    max-height: 200px;
    overflow: scroll;
    z-index: 1;
    font-size: 0.8em;
    background: var(--darker-white);
    width: 100%;

    border-radius: 1em;
    span {
      padding: 0.5em 1em;
    }
  }
}

.title-text {
  gap: 0.5rem;
}
.timer {
  position: absolute;
  bottom: 13%;
}

.cont {
  position: relative;
}

.input {
  background: #f0f0f0;
  border-radius: 8px 8px 0px 0px;
  height: 48px;
  padding: 10px;
}

.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 28px;
}

.content-container {
  gap: 24px;
}
.fee-network {
  gap: 10px;
}
button {
  height: 44px;
}
@media (max-width: 500px) {
  .modal {
    max-width: 90%;
    gap: 0.5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>
