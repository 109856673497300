<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal">
      <h3 class="align-self-center">
        واریز
        {{ $coinLabel[depositData] }}
      </h3>

      <template  v-show="!isNetwork">
<div class="drop-container d-flex flex-col gap-xss">
          <button class="align-self-start" @click="drop = !drop">
          شبکه
          {{ selected_net }}
        </button>
        <div class="drop d-flex flex-col gap-xss pointer" v-if="drop">
          <span
          class="hover"
            v-for="(item, index) in networks"
            :key="index"
            @click="selectNet(item)"
            >{{ item }}</span
          >
        </div>
</div>
      </template>

      <template v-if="isNetwork">
        <p class="regular">
          لینک زیر آدرس کیف پول شما می باشد. برای واریز کوین می توانید از این
          آدرس یا اسکن کد زیر استفاده کنید.
        </p>
        <img
          class="align-self-center"
          :src="'data:image/png;base64,' + qrCode"
          width="200"
          height="200"
          alt=""
        />
        
        <div class="link-container">
          <span class="alert-link small green" v-if="alertLink">کپی شد</span>
          <div class="input-container">
            <img
              src="@/assets/Icons/clipboard.svg"
              alt=""
              @click.prevent="copyLink(address)"
            />
            <input type="text" v-model="address" readonly />
          </div>
        </div>
        <div class="warning">
          <img src="@/assets/Icons/warning.png" alt="" width="20" height="20" />
          <p class="regular">
            توجه کنید که واریز به هر آدرسی دیگری جز این ادرس موجب از دست رفتن
            دارایی شما می شود.این عمل بازگشت پذیر نخواهد بود و هاب هیچ مسولیتی
            را در این باره قبول نخواهد کرد
          </p>
        </div>
      </template>

      <button :disabled="!isNetwork" class="btn-primary w100" @click.prevent="checkWallet">برررسی واریز</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Deposit",
  props: ["depositData"],
  data() {
    return {
      link: "",
      alertLink: false,
      isNetwork: false,
      selected_net: " را انتخاب نمایید",
      networks: "",
      amount: "",
      address: "",
      qrCode: "",
      tag: "",
      txid: "",
      token: false,
      drop: false,
      txIdIsNeeded: false,
      enable: false,
    };
  },
  methods: {
    selectNet(item) {
      this.selected_net = item;
      this.drop = !this.drop;
      this.isNetwork = true;
      this.getQR();
    },
    async getNetwork() {
      const res = await this.$axios.get("/coins");
      const coin = res.content.find((coin) => coin.coin === this.depositData);
      let networks = coin.tokenTypeDetails;

      networks = networks.map((item) => item.tokenType);

      this.networks = networks;
    },
    copyLink(link) {
      navigator.clipboard.writeText(link);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
  async checkWallet() {
      this.state.loading = true
      // const token = this.token ? 'ERC20' : 'TRC20'
      let query = {
        relatedCoin: this.depositData,
        tokenType: this.selected_net,
        // walletType: (this.exchange ? 'EXCHANGE' : 'P2P')
      }
      if (this.txIdIsNeeded) {
        query.txid = this.txid
      }
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
      if (res.baseDTO.amount) {
        text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.depositData]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
    },





    async getQR() {
      this.state.loading = true;
      const token = this.selected_net;
      let params = {
        relatedCoin: this.depositData,
      };
      if (this.depositData === "TETHER") {
        params.tokenType = token;
      }
      const res = await this.$axios(
        "/wallets/customer/wallet-address?walletType=EXCHANGE&" +
          "tokenType=" +
          token,
        {
          params,
        }
      ).catch(() => {
        this.$emit("close");
      });

      if (res) {
        this.address = res.baseDTO.address;
        this.qrCode = res.baseDTO.qrCode;
        this.tag = res.baseDTO.tag;
      }
    },
  },
  mounted() {
    this.getNetwork();
  },
};
</script>

<style lang="scss" scoped>
.warning {
  display: flex;
  gap: 2px;
}
.drop-container{
position: relative;
.drop{
  position: absolute;
  top: 40px;
  max-height: 200px;
  overflow: scroll;
  z-index: 1;
  font-size: .8em;
  background: var(--darker-white);
  width: 100%;

  border-radius: 1em;
  span{
      padding:.5em 1em;
  }
}
}
.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 28px;
}

.link-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 7px;
  flex-wrap: wrap;
  .regular {
    width: 100%;
    max-width: 5%;
    flex: 1 1 auto;
  }
  .input-container {
    flex: 1 1 auto;
    max-width: 491px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px;
    border-radius: 8px;
    background: var(--gray-lighter);
  }
}
button {
  height: 44px;
}

@media (max-width: 500px) {
  .modal {
    max-width: 90%;
    gap: 0.5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>
