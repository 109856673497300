<template>
  <div class="wallet d-flex flex-col">
    <RialDeposit v-if="RialDeposit" @close="RialDeposit=false"/>
    <RialWithdraw v-if="RialWithdraw" @close="RialWithdraw=false" :balance='wallet.balance'/>
    <div class="wallet-header">
      <div class="inventory">
        <p class="regular gray">موجودی کیف پول</p>
        <span class="inventory-price"><h2 class="bolder">{{$toLocal(wallet.balance,$decimal['TOMAN'])}}</h2>
          <small class="gray">تومان</small>
          <span class="success">
            3.2%
            <inline-svg :src="require('../../../assets/Icons/arrow.svg')" alt="" class="rotate " />
          </span>
        </span>

      </div>
      <div class="buttons">
        <button class="btn-green-outline small" @click="RialDeposit = !RialDeposit">واریز   تومانی</button>
        <button class="btn-red-outline small"  @click="RialWithdraw = !RialWithdraw">برداشت   تومانی</button>
      </div>
    </div>
<Wallets :wallets='wallets'/>
  </div>
</template>
<script>
import RialDeposit from "@/components/Panel/WalletP2P/RialDeposit";
import RialWithdraw from "@/components/Panel/WalletP2P/RialWithdraw";
import Wallets from "@/components/Panel/WalletP2P/Wallets.vue";
export default {
  name: 'WalletP2P',
  components: {Wallets, RialWithdraw, RialDeposit},
  data() {
    return {
      RialDeposit:false,
      RialWithdraw:false,
      wallets:'',
      wallet: {
        balance: 0,
        transactions: [],
        

      },
    };
  },
    methods:{
        async getWallets() {
      this.state.loading = true
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')

      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
        this.wallets = entireRes.filter(item=> item[0] !=='TOMAN')
        this.wallet.balance = res["TOMAN"].credit


      }
      this.loading = false

    },

  },
  mounted(){
    this.getWallets();
  }
}
</script>
<style lang="scss" scoped>
.wallet{
gap: 40px;
}
.wallet-header{
  display: flex;
  flex-wrap: wrap;
  gap: 4em;
  align-items: center;
}
.inventory{
  display: flex;
  flex-direction: column;
gap: 4px;
  .inventory-price{
    display: flex;
    gap: .5rem;
    align-items: center;
  }



}
.buttons{
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  button{
    min-width: 140px;
    width: 100%;
    max-width: 140px !important;
    height: 44px;
  }
}
</style>