<template>
  <div class="wallets d-flex flex-col">
    <Deposit v-if="Deposit" @close="Deposit = false" :depositData='depositData'/>
    <Withdraw
      v-if="Withdraw"
      @close="Withdraw = false"
      :coin="withdrawData"

  
    />
    <div class="table-container">
      <div class=" d-flex justify-space-between w100 head">
        <span class="gray small bolder">کیف پول</span>
        <span class="gray small bolder">موجودی</span>
        <span class="gray small bolder">معادل</span>
        <span class="gray small bolder">عملیات</span>
      </div>
    </div>
   <div class="d-flex flex-col cont">
    <div class="d-flex justify-space-between w100 flex-wrap row" v-for="(item, index) in wallets" :key="index">
        <span class="small d-flex gap-xss image-container">
       <img :src="'/coins/'+ item[0] + '.png' " :alt="item[0]" width="28" height="28"/>
          <span class="d-flex flex-col justify-content-center">
          <p>{{ $coins[item[0]].persianName }}</p>
          <p class="brand">{{ $coins[item[0]].code }}</p>
          </span>
        
        </span>
        <span class="small value "> <span class="mobile">مقدار:</span>      {{ $toLocal(item[1].credit, 0) }}
              {{ $coins[item[0]].code }} </span>
        <span class="small value"> <span class="mobile">موجودی:</span> <span class="small gray">TMN</span>     {{ $toLocal(item[1].wholePriceOfCredit, 0) }}</span>
        <span class="btns d-flex align-items-center">
          <button class="btn-green-outline btn" @click="openDepositModal(item[0]);">واریز</button>
          <button class="btn-red-outline btn" @click="openWithdrawModal(item[0])">برداشت</button>
          <button class="btn-secondary-outline btn" @click.prevent="$router.push({name:'AdvanceTrade'})">معامله</button>
        </span>


      </div>
   </div>





  </div>
</template>

<script>
import Deposit from "@/components/Panel/WalletP2P/Deposit";
import Withdraw from "@/components/Panel/WalletP2P/Withdraw";
export default {
  name: "Wallets",
  props:['wallets'],
  components: { Withdraw, Deposit },
  data() {
    return {
      Deposit: false,
      min: 0.01,
      depositData:'',
      withdrawData:'',
      Withdraw: false,
      tomancash:'',
      coin: "BTC",
      networkFee: "0.0005",
      wallet: [
        {
          name: "BTC",
          amount: 0.1,
          toman: 1000000,
        },
      ],
    };
  },
  methods: {
    openDepositModal(coin) {
      this.depositData = coin;
      console.log(coin)
      this.Deposit = true;
    },
    openWithdrawModal(coin) {
      this.withdrawData = coin;
      this.Withdraw = true;
    },
  },

};
</script>

<style lang="scss" scoped>
.table-container {
  max-height: 408px;
  overflow-y: auto;
}
.wallets {
  padding: 1rem;
  background: #f8f8f8;
  /* card/def */

  box-shadow: -1px 4px 8px -1px rgba(192, 222, 234, 0.2);
  border-radius: 12px;
}

.head{
  height: 56px;
}
.mobile{
  display: none;
}
.btns {
  gap: 1rem;
width: 100%;
max-width: 400px;
  .btn {
    height: 44px !important;
    flex: 1 1 128px;
    width: 100%;
    max-width: 128px;
  }
}

.cont{
  gap:1rem;
}
.image-container{
  min-width: 150px;
}
.row{
  min-height: 56px;
  padding:1rem 0;
  border-bottom: 1px solid var(--gray-lighter);

}
.value{
  width: 100%;
  max-width: 300px;
  text-align: left;
  margin-right: 2.1em;
}

@media (max-width: 1250px) {
  .wallets {
    max-width: 90%;
  }
  .row{
    flex-direction: column !important;
    gap:1rem;

  }
  .image-container{
    min-width: unset;
  }
  .value{
    width: unset !important;
    max-width: unset !important;
    text-align: right !important;
    margin-right: 0;
  }


}


@media (max-width:712px){
.head{
  display: none;
}

    .mobile{
    display: inline;
  }
}
</style>