<template>
<div class="modal-backdrop" @click.self="$emit('close')" >
  <div class="modal">
    <h2>واریز   تومانی</h2>
    <p class="regular">
      جهت افزایش اعتبار کیف پول خود با استفاده از کارت های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت،حتما باید از کارت های بانکی به نام خودتان که در پروفایلتان تایید شده است استفاده نمایید.
    </p>
    <input type="text" class="selector" placeholder="مبلغ واریزی" v-model="amount">
    <select name="cars" id="cars" class="selector" >
      <option v-for="(card,index) in Cards" :value="card.cardNumber" :key="index">{{card.cardNumber}}</option>
    </select>
    <button class="btn-primary w100" :disabled="amount ===0" @click="$emit('close')" >تایید</button>
  </div>
</div>
</template>

<script>
export default {
  name: "RialDeposit",
  data(){
    return{
      amount:0,
      Cards:[
          {
        cardNumber: "1234567890123456",
      },
        {
          cardNumber: "8967612678678863",
        },
        {
          cardNumber: "6219861938976562",
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-width: 460px;
  gap: 28px;
  min-height: 300px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
}
.selector{
  width: 100%;
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
height: 48px;
  padding: 10px;
}
button{
  height: 44px;
}
@media (max-width:500px){
  .modal{
    max-width: 90%;
    gap: .5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>