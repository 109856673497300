<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal">
      <h2 class="align-self-center">برداشت تومانی</h2>
      <p class="regular">
        درخواست تسویه تومانی در روزهای تعطیل به دلیل تعطیلی بانک و عدم فعالیت
        سیستم پایا انجام نمی شود درخواست تسویه های بالای 100 میلیون تومان به
        دلیل محدودیت های بانکی حداکثر تا یک روز آینده کاری از زمان ثبت درخواست
        انجام خواهد شد
      </p>
      <div class="content-container d-flex flex-col">
        <div class="d-flex justify-space-between w100 cont">
          <span class="small gray">موجودی شما:</span>
          <span class="small gray">{{
            `${$toLocal(balance, $decimal["TOMAN"])} TMN`
          }}</span>
        </div>
        <input
          class="input w100"
          type="text"
          placeholder="مبلغ برداشت"
          v-model="amount"
        />
        <select v-model="walletAddress">
          <option
            v-for="(bank, index) in Banks"
            :value="bank.cardNumber"
            :key="index"
          >
            {{ bank.cardNumber }}
          </option>
        </select>
        <input
          v-if="otpSent"
          class="input w100"
          type="text"
          placeholder="شناسه دو عاملی"
          v-model="code"
        />
        <span v-if="otpSent" class="timer align-self-end small">{{
          countdown
        }}</span>
      </div>

      <button
        :disabled="!!disabled"
        class="btn-primary w100"
        @click.prevent="withdraw"
        v-if="!otpSent"
      >
        {{ !!disabled ? disabled : "ارسال کد" }}
      </button>
      <button
        class="btn-primary w100"
        v-if="timeout"
        @click="(countdown = 10), (timeout = !timeout), countDownTimer()"
      >
        ارسال مجدد
      </button>
      <button
        @click.prevent="submitWithdrawRequest"
        class="btn-primary w100"
        v-if="!timeout && otpSent"
      >
        تایید
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RialWithdraw",
  props: {
    balance: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      otpSent: false,
      countdown: 120,
      timeout: false,
      Banks: "",
      timeOut: null,
      walletAddress: "",
      coins: "",
      amount: 0,
      code: "",
      transaction: {
        code: "",
      },
      min: 0,
      max: 0,
    };
  },
  computed: {
    disabled() {
      let Error = "";

      if (!this.$S2N(this.amount)) {
        Error = "مبلغ وارد شده صحیح نیست";
      } else if (this.$S2N(this.amount) > this.$S2N(this.balance)) {
        Error = "مبلغ وارد شده بیشتر از موجودی کیف پول شما می باشد";
      } else if (this.$S2N(this.amount) > this.$S2N(this.max)) {
        Error = "مبلغ وارد شده بیشتر از حداکثر برداشتی است";
      } else if (this.$S2N(this.amount) < this.$S2N(this.min)) {
        Error = "مبلغ وارد شده کمتر از حداقل برداشتی است";
      } else if (!this.walletAddress) {
        Error = "آدرس کیف پول وارد نشده است";
      }

      // return (
      //   !this.$S2N(this.amount) ||
      //   this.$S2N(this.amount) > this.$S2N(this.cash) ||
      //   this.$S2N(this.amount) > this.$S2N(this.max) ||
      //   this.$S2N(this.amount) < this.$S2N(this.min) ||
      //    !this.walletAddress
      // );
      return Error;
    },
  },
  methods: {
    async withdraw() {
      this.state.loading = true;
      this.countDown = 120;
      const res = await this.$axios.post("/users/send-code");
      if (res.message === "code sent successfully.") {
        this.otpSent = !this.otpSent;
        this.countDownTimer();
        // this.$emit(
        //     "two-fa",
        //     [transaction, res.baseDTO.towStepAuthenticationType]
        // );
      }
    },
    countDownTimer() {
      console.log(this.walletAddress);
      if (this.countdown > 0) {
        this.timeOut = setTimeout(() => {
          this.countdown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");
        this.timeout = true;
      }
    },
    async getBanks() {
      this.state.loading = true;
      const res = await this.$axios.get("/users/verified-bank-accounts");
      this.Banks = res.content;
    },
    async getDataCoins() {
      this.state.loading = true;
      const res = await this.$axios.get("/coins/details");
      this.coins = res.content;
      console.log(res.content);
      this.role = this.coins.find((a) => a.coin === "TOMAN");
      console.log(this.role);
      this.min = this.role.tokenTypeDetails[0].minWithdraw;
      this.max = this.role.tokenTypeDetails[0].maxWithdraw;
      console.log(this.min, this.max);
    },

    async submitWithdrawRequest() {
      //  this.closemodale();
      this.state.loading = true;
      this.transaction.amount = this.$S2N(this.amount);
      this.transaction.destinationWalletAddress = this.walletAddress;
      this.transaction.relatedCoin = "TOMAN";
      this.transaction.code = this.code;
      const [res] = await this.$http.post(
        `/wallets/withdrawal-request`,
        this.transaction
      );
      if (res) {
        this.$emit("close");
        this.$error("درخواست برداشت شما با موفقیت ثبت شد", "", "success");
        ++this.state.requestChanged;
      }
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
  mounted() {
    this.getBanks();
    this.getDataCoins();
  },
};
</script>

<style lang="scss" scoped>
select {
  height: 48px;
  border-radius: 8px 8px 0 0;
  padding: 0.5rem;
  background: #f0f0f0;
}
.timer {
  position: absolute;
  bottom: 13%;
}
.cont {
  position: relative;
}
.input {
  background: #f0f0f0;
  border-radius: 8px 8px 0px 0px;
  height: 48px;
  padding: 10px;
}

.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 28px;
}

.content-container {
  gap: 24px;
}

button {
  height: 44px;
}
@media (max-width: 500px) {
  .modal {
    max-width: 90%;
    gap: 0.5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>
